import { LOADING, SET_MESSAGE } from "../../Actions/Types";
import { saveNormalWebsiteApi } from "../../Api/DomainApi/SaveNormalDomainApi"
import encodeBase64 from "../../Helpers/encodeBase64";
import { getDomainListService } from "./DomainList";

// service to save new domain for normal user
const saveNormalDomainService = (
    token: string, // access token
    newWebsiteURL: string, // website url
    userID: number, // user ID
    newWebsitePlanID: number, // plan package Id for the new website
    newWebsitePlanType: string, // plan package type
    activeLanguageCode: string, // language code
    freeVersionFlag: boolean, // free version flag
    dispatch: Function, // dispatch function to store redux data
    navigate: Function, // navigate function for routing
    t: Function // i18next t function
) => {

    // Set Loading
    dispatch({
        type: LOADING,
        payload: true,
    });

    // API to save normal website
    saveNormalWebsiteApi(token, newWebsiteURL, userID, newWebsitePlanID).then((res) => {
        // check if response data is available
        if (res.Data) {

            const urlString = `${newWebsitePlanID}|${newWebsiteURL.replace(/^(?:https?:\/\/)?(?:www\.)?/i, '').split('/')[0]}|${newWebsitePlanType}|${userID}|${activeLanguageCode}|1`;
            const encodedString = encodeBase64(urlString);

            // check if free version
            if (freeVersionFlag) {
                // service to get domain list
                getDomainListService(token, dispatch).then(() => {
                    
                    // remove Loading
                    dispatch({
                        type: LOADING,
                        payload: false,
                    });

                    // dispatch success message
                    dispatch({
                        type: SET_MESSAGE,
                        payload: {
                            "toast_message": t('Domain Added Successfully'),
                            "toast_type": "success"
                        }
                    });

                    // navigate to orders page with update flag
                    navigate(`/front/orders?website_update=true`);
                    
                });
            }
            else {
                // remove Loading
                dispatch({
                    type: LOADING,
                    payload: false,
                });

                // navigate to plan purchase page
                navigate(`/front/package/${encodedString}`);
            }



        } else {
            
            // set error message
            dispatch({
                type: SET_MESSAGE,
                payload: {
                    "toast_message": res.error,
                    "toast_type": "danger"
                }
            });

            // remove Loading
            dispatch({
                type: LOADING,
                payload: false,
            });
        }
    })
}

export { saveNormalDomainService }