import { useParams } from "react-router";
import decodeBase64 from "../../Helpers/decodeBase64";
import { useEffect, useState } from "react";
import encodeBase64 from "../../Helpers/encodeBase64";
import { toInteger } from "lodash";
import { useTranslation } from "react-i18next";
import DashboardPagesTemplate from "../../Template/DashboardPagesTemplate";
import { LOADING } from "../../Actions/Types";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { StoreProptypes } from "../../PropTypes/StoreProptypes";
import DashboardBlankPagesTemplate from "../../Template/DashboardBlankPagesTemplate";

import "../../Assets/scss/pages/payment-page.scss";

const baseURL = `${process.env.REACT_APP_BASE_URL}`;

// component to render payment pages
const PlanPaymentPage = () => {

    // Function
    const { t } = useTranslation();
    const dispatch = useDispatch();

    // Redux Data
    const { currentUserStatus, isLoading, user, currentWebsite } = useSelector((store: StoreProptypes) => store);

    // URL Token
    const { token } = useParams();

    const [iframURLType, setIframURLType] = useState<number | null>();
    const [packageID, setPackageID] = useState<number | null>(null);
    const [subPackageID, setSubPackageID] = useState<number | null>(null);
    const [domain, setDomain] = useState('');
    const [termPlan, setTermPlan] = useState('');
    const [userID, setUserID] = useState<number | null>(null);
    const [activeLanguageCode, setActiveLanguageCode] = useState('');
    const [planEncodedString, setPlanEncodedString] = useState('');


    useEffect(() => {
        // show loader on page load
        if (!isLoading) {
            dispatch({
                type: LOADING,
                payload: true,
            });
        }
    }, [])

    // function to calculate iframe height dynamically
    const iframeHeight = () => {

        const frame = document.getElementById('myIframe') as HTMLIFrameElement;

        if (frame) {

            const iframeDoc = frame.contentDocument || frame.contentWindow?.document;

            // check if iframe is fully loaded
            if (iframeDoc?.readyState === 'complete') {

                const contentWindow = frame.contentWindow;
                if (contentWindow) {
                    // apply height
                    frame.style.height = contentWindow.document.body.scrollHeight + 250 + 'px';

                    dispatch({
                        type: LOADING,
                        payload: false,
                    });
                }
            } else {
                setTimeout(() => {
                    iframeHeight();
                }, 100)
            }

        }
    }

    useEffect(() => {
        // check if payment token available
        if (token) {
            const tokenObj = decodeBase64(token).split("|");

            //console.info("tokenObj[1] ==>", tokenObj[1], tokenObj);

            setPackageID(toInteger(tokenObj[0]));
            setDomain(tokenObj[1]);
            setTermPlan(tokenObj[2]);
            setUserID(toInteger(tokenObj[3]));
            setActiveLanguageCode(tokenObj[4]);
            setIframURLType(toInteger(tokenObj[5]));
            setSubPackageID(toInteger(tokenObj[6]))
        } else {
            setPackageID(null);
            setDomain('');
            setTermPlan('');
            setUserID(null);
            setActiveLanguageCode('');
            setIframURLType(null);
            setSubPackageID(null)
        }
    }, [token])

    useEffect(() => {

        // check if package Id, website, plan term, user id and active language are available
        if (packageID !== null && domain !== '' && termPlan !== '' && userID !== null && activeLanguageCode !== '') {

            let urlString;

            // check if iframe url type is 1
            if (iframURLType === 1) {
                // For package URL token
                urlString = `${packageID}|${domain}|${termPlan}|${userID}|${activeLanguageCode}`;
                const planEncodedString1 = encodeBase64(urlString);
                setPlanEncodedString(`package/${planEncodedString1}`);
            }
            // check if iframe url type is 10
            else if (iframURLType === 10) {
                // For package URL token
                urlString = `${packageID}|${domain}|${termPlan}|${userID}|${activeLanguageCode}|0|0|1|1`;
                const planEncodedString1 = encodeBase64(urlString);
                setPlanEncodedString(`package/${planEncodedString1}`);
            }
            // check if iframe url type is 2
            else if (iframURLType === 2) {
                urlString = `${packageID}-${subPackageID}|${domain}|${termPlan}|${userID}|${activeLanguageCode}`;
                const planEncodedString2 = encodeBase64(urlString);
                setPlanEncodedString(`package/${planEncodedString2}`);
            }
            // check if iframe url type is 3
            else if (iframURLType === 3) {
                urlString = `${packageID}|${domain}|${termPlan}|${userID}|${activeLanguageCode}`;
                const planEncodedString3 = encodeBase64(urlString);
                setPlanEncodedString(`pre-purchase/${planEncodedString3}`);
            }
            // check if iframe url type is 4
            else if (iframURLType === 4) {
                urlString = `${userID}|${activeLanguageCode}`;
                const planEncodedString4 = encodeBase64(urlString);
                setPlanEncodedString(`bulk-purchase/${planEncodedString4}`);
            }
            // check if iframe url type is 5 -- renew plan for multi site partner user
            else if (iframURLType === 5) {
                //console.log(currentWebsite?.expired_package_detail[0].parent_id);
                let packageId = 0;

                if (currentWebsite?.is_expired === 1) {
                    packageId = currentWebsite?.expired_package_detail[0].parent_id;
                }
                else if (currentWebsite?.is_expired === 0) {
                    packageId = currentWebsite?.current_package_only[0].parent_id;
                }

                urlString = `${packageID}|${domain}|${termPlan}|${userID}|${activeLanguageCode}|0|${packageId}-1`;
                const planEncodedString5 = encodeBase64(urlString);
                //console.log(`test: url ${urlString}`);
                //console.log(`test: current active package id ${user?.current_active_package_id} `);
                //console.log(`test: encoded string ${planEncodedString5}`)
                setPlanEncodedString(`multi-store-payment/${planEncodedString5}`);
            }
            // check if iframe url type is 50
            else if (iframURLType === 50) {
                urlString = `${packageID}|${domain}|${termPlan}|${userID}|${activeLanguageCode}|1`;
                const planEncodedString5 = encodeBase64(urlString);
                setPlanEncodedString(`multi-store-payment/${planEncodedString5}`);
            }
            // check if iframe url type is 6
            else if (iframURLType === 6) {
                // For package URL token
                urlString = `${packageID}|${domain}|${termPlan}|${userID}|${activeLanguageCode}|3`;
                const planEncodedString1 = encodeBase64(urlString);
                setPlanEncodedString(`package/${planEncodedString1}`);
            }
            // check if iframe url type is 7
            else if (iframURLType === 7) {
                // For package URL token
                urlString = `${packageID}|${domain}|${termPlan}|${userID}|${activeLanguageCode}`;
                const planEncodedString1 = encodeBase64(urlString);
                setPlanEncodedString(`update-card/${planEncodedString1}`);
            }

        } else {
            setPlanEncodedString('');
        }

    }, [iframURLType])

    // calculate iframe height on iframe load function
    const iframeLoader = () => {
        //console.info("iframeLoader call...");
        iframeHeight();
    }

    return (
        <>
            {currentUserStatus && (
                <>
                    {currentUserStatus.isInitUser ? (
                        <>
                            <DashboardBlankPagesTemplate>
                                <div className="aioa_dashboard-blank-pages-content-inner-wrapper">
                                    <div className="aioa_dashboard-payment-screen-main">
                                        {((iframURLType === 1 || iframURLType === 2 || iframURLType === 3 || iframURLType === 4 || iframURLType === 5 || iframURLType === 50 || iframURLType === 6 || iframURLType === 10 || iframURLType === 7) && planEncodedString !== '') && (
                                            <>
                                                <iframe title={t("Payment for All in One Accessibility Plans/Add Ons").toString()} onLoad={iframeLoader} id="myIframe" src={`${baseURL}/${planEncodedString}`} style={{ width: '100%' }}></iframe>
                                            </>
                                        )}
                                    </div>
                                </div>
                            </DashboardBlankPagesTemplate>
                        </>
                    ) : (
                        <>
                            <DashboardPagesTemplate>

                                <div className="aioa_dashboard-payment-screen-main">
                                    {((iframURLType === 1 || iframURLType === 2 || iframURLType === 3 || iframURLType === 4 || iframURLType === 5 || iframURLType === 50 || iframURLType === 6 || iframURLType === 7 || iframURLType === 10) && planEncodedString !== '') && (
                                        <>
                                            <iframe title={t("Payment for All in One Accessibility Plans/Add Ons").toString()} onLoad={iframeLoader} id="myIframe" src={`${baseURL}/${planEncodedString}`} style={{ width: '100%' }}></iframe>
                                        </>
                                    )}
                                </div>

                            </DashboardPagesTemplate>
                        </>
                    )}
                </>
            )}
        </>
    )
}

export default PlanPaymentPage;
