import "../../Assets/scss/pages/widget-manage-plan.scss";

import { useTranslation } from "react-i18next";
import DashboardPageTitle from "../../Components/InnerPageTitle";
import DashboardPagesTemplate from "../../Template/DashboardPagesTemplate";
import { useSelector } from "react-redux";
import { StoreProptypes } from "../../PropTypes/StoreProptypes";
import { Accordion } from "react-bootstrap";
import AllUserOnlyActivePlanInfo from "../../Components/ManagePlan/CurrentActivePlanInfo";
import AllUserOnlyExpiredPlanInfo from "../../Components/ManagePlan/ExpiredPlanPlanInfo";
import AllUserAllPlanInfo from "../../Components/ManagePlan/AllPlanInfo";
import FreeWidgetPlanInfo from "../../Components/ManagePlan/FreeWidgetPlanInfo";
import AllUserOnlyTrialPlanInfo from "../../Components/ManagePlan/CurrentTrialPlanInfo";
import PlatformWidgetCurrentActivePlanInfo from "../../Components/ManagePlan/PlatformWidgetPlanInfo";
import AllUserOnlyCanceledPlanInfo from "../../Components/ManagePlan/CanceledPlanInfo";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";


// component to render manage plan page
const ManagePlanPage = () => {

  // Redux Data
  const { currentWebsite, currentWebsitePlanStatus } = useSelector((store: StoreProptypes) => store);

  // Functions
  const { t } = useTranslation();

  const navigate = useNavigate();

  useEffect(() => {
    if (currentWebsite?.platform === 'wix' || currentWebsite?.platform === 'shopify' || currentWebsite?.platform === 'plesk') {
      navigate('/front');
    }
  }, [currentWebsite])

  return (
    <>
      <DashboardPagesTemplate>
        <DashboardPageTitle
          pageTitle={`${t("Manage Plan")}`}
          subPageTitle={`For ${currentWebsite?.domain}`}
        />

        <div className="aioa_dashboard-manage-plan">
          {currentWebsite
            && ((currentWebsite.platform === "wix"
              || currentWebsite.platform === 'shopify'
              || currentWebsite.platform === "Opencart-Pro"
              || currentWebsite.platform === "Magento-Pro")) ? (
            <>
              <PlatformWidgetCurrentActivePlanInfo />
            </>
          ) : (
            <>
              {currentWebsite
                && currentWebsitePlanStatus
                && (
                  <>
                    {currentWebsitePlanStatus.planExpiredData === null
                      && currentWebsitePlanStatus.planName === null ? (
                      <>
                        <AllUserAllPlanInfo />
                      </>
                    ) : (
                      <>
                        {currentWebsitePlanStatus.isExpired ? (
                          <>
                            {currentWebsite.expired_package_detail
                              && currentWebsite.expired_package_detail.length > 0
                              && (
                                <>
                                  <AllUserOnlyExpiredPlanInfo />
                                </>
                              )}
                          </>
                        ) : currentWebsitePlanStatus.isCanceledPlan
                          ? (
                            <>
                              <AllUserOnlyCanceledPlanInfo />
                            </>
                          ) : (
                            <>
                              {currentWebsite.current_package_only
                                && currentWebsite.current_package_only.length > 0
                                && (
                                  <>
                                    {currentWebsitePlanStatus.isTrial
                                      ? (
                                        <>
                                          <AllUserOnlyTrialPlanInfo />
                                        </>
                                      ) : currentWebsitePlanStatus.isFreeWidget
                                        ? (
                                          <>
                                            <FreeWidgetPlanInfo />
                                          </>
                                        ) : (
                                          <>
                                            <>
                                              <AllUserOnlyActivePlanInfo />
                                            </>
                                          </>
                                        )}
                                  </>
                                )}
                            </>
                          )}
                      </>
                    )}
                  </>
                )}
            </>
          )}
        </div>



      </DashboardPagesTemplate>
    </>
  )
}

export default ManagePlanPage;