import { useTranslation } from "react-i18next";
import "../../Assets/scss/components/dashboard-page-title.scss";
//import { useState } from "react";
import { useSelector } from "react-redux";
import { StoreProptypes } from "../../PropTypes/StoreProptypes";

interface PropType {
    pageTitle: string, // page title string
    subPageTitle?: string, // sub page title
    showFreeWidgetStrip?: boolean // flag to display free widget label
}

// component to display page title
const DashboardPageTitle = (prop: PropType) => {

    const { pageTitle, subPageTitle, showFreeWidgetStrip } = prop;

    const { currentWebsitePlanStatus } = useSelector((store: StoreProptypes) => store);

    const { t } = useTranslation();

    //const [isFreeWidgetToggle, setIsFreeWidgetToggle] = useState(false);

    return (
        <>
            <div className="aioa_dashboard-page-title-wrapper">
                <div className="aioa_dashboard-page-title">
                    <h1 className="mb-0" tabIndex={-1}>{t(pageTitle)}</h1>
                    {subPageTitle && <div className="subtitle mb-0">{t(subPageTitle)}</div>}
                </div>
                <div className="aioa_dashboard-page-button">

                    {showFreeWidgetStrip && currentWebsitePlanStatus.isFreeWidget && (
                        <>
                            {/* <Form.Check
                                className='free-widget-switch'
                                type="switch"
                                id="custom-widget-toggle"
                                label={`Show Results with Widget Remediation`}
                                checked={isFreeWidgetToggle}
                                onChange={(e) => setIsFreeWidgetToggle(e.target.checked)}
                            /> */}
                        </>
                    )}

                </div>
            </div>
        </>
    )
}

export default DashboardPageTitle;