import { Button, Card, Col, Form, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { StoreProptypes } from "../../PropTypes/StoreProptypes";
import { useEffect, useState } from "react";
import { websitePlanPropType } from "../../PropTypes/WebsitePlanPropType";
import encodeBase64 from "../../Helpers/encodeBase64";
import { saveNormalDomainService } from "../../Services/DomainService/AddNormalDomain";
import StrikePrice from "../../Helpers/strikePrice";
import PaymentButtonBlock from ".";
import parseISODate from "../../Helpers/parseISODate";
import { currentPackageOnlyPropType } from "../../PropTypes/WebsitePropTypes";

interface PropTypes {
    activePlanType: number, // 1-widget plan, 2- multi domain, 3 - Pre purchase
    activePlanInterval: string | null, // plan interval
    activePlanPrice: number, // active plan price
    activePlanDomain: string, // active plan domain
    forAddNewDomain?: boolean // flag for add new domain
}

// component to render plan/pricing list
const UpgradePlanList = (props: PropTypes) => {

    // Redux Data
    const { currentWebsitePlanList, user, activeLanguage, accessToken, currentUserDiscount, currentWebsite, currentWebsitePlanStatus } = useSelector((store: StoreProptypes) => store);

    // Props
    const { activePlanPrice, activePlanInterval, activePlanType, activePlanDomain, forAddNewDomain } = props;

    //console all props
    console.log("activePlanPrice", activePlanPrice);
    console.log("activePlanInterval", activePlanInterval);
    console.log("activePlanType", activePlanType);
    console.log("activePlanDomain", activePlanDomain);
    console.log("forAddNewDomain", forAddNewDomain);


    // Functions
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    // State
    const [upgradePlanList, setUpgradePlanList] = useState<websitePlanPropType[]>([]);
    const [planInterval, setPlanInterval] = useState<string>('Y');
    const [disabledButton, setDisabledButton] = useState<boolean>(false);
    const [freePlanID, setFreePlanID] = useState<number>();

    const [planPackage, setPlanPackage] = useState<currentPackageOnlyPropType>();


    // Disable the "Select Plan" button for users in department 1
    useEffect(() => {
        // check if admin user
        if (user && user.department === 1) {
            setDisabledButton(true);
        } else {
            setDisabledButton(false);
        }
    }, [user])

    useEffect(() => {
        if (currentWebsitePlanStatus.isExpired) {
            setPlanPackage(currentWebsite?.expired_package_detail[0]);
        }
        else if (currentWebsitePlanStatus.isCanceledPlan) {
            setPlanPackage(currentWebsite?.canceled_package_only[0]);
        }
        else {
            setPlanPackage(currentWebsite?.current_package_only[0]);
        }
    }, [currentWebsitePlanStatus])



    // Filter the plan list based on conditions and exclude plans with is_dynamic === 1
    useEffect(() => {

        // check if plan list available
        if (currentWebsitePlanList && Object.keys(currentWebsitePlanList).length > 0) {
            let tempPlanList: websitePlanPropType[] = [];

            // 1 = widget plan
            if (activePlanType === 1) {
                if (planPackage) {
                    // check if dynamic plan exists
                    if (planPackage.platforms === "dynamic") {
                        currentWebsitePlanList[`dynamic`]
                            .filter(planList => planList.is_dynamic !== 1) // Exclude is_dynamic=1
                            .forEach(planList => {
                                if ((activePlanInterval === "M" || activePlanInterval === null) && planList.monthly_price >= activePlanPrice && planList.id === planPackage.package_id) {
                                    tempPlanList = [...tempPlanList, planList];
                                } else if (activePlanInterval === "Y" && planList.price >= activePlanPrice && planList.id === planPackage.package_id) {
                                    tempPlanList = [...tempPlanList, planList];
                                }
                            });

                        // check if plan list is empty
                        if (!tempPlanList.length) {
                            currentWebsitePlanList[`normal`]
                                .filter(planList => planList.is_dynamic !== 1) // Exclude is_dynamic=1
                                .forEach(planList => {
                                    if ((activePlanInterval === "M" || activePlanInterval === null) && planList.monthly_price >= activePlanPrice) {
                                        tempPlanList = [...tempPlanList, planList];
                                    } else if (activePlanInterval === "Y" && planList.monthly_price >= activePlanPrice) {
                                        tempPlanList = [...tempPlanList, planList];
                                    }
                                });
                        }
                    } else {
                        currentWebsitePlanList[`normal`]
                            .filter(planList => planList.is_dynamic !== 1) // Exclude is_dynamic=1
                            .forEach(planList => {
                                if ((activePlanInterval === "M" || activePlanInterval === null) && planList.monthly_price >= activePlanPrice) {
                                    tempPlanList = [...tempPlanList, planList];
                                } else if (activePlanInterval === "Y" && planList.monthly_price >= activePlanPrice) {
                                    tempPlanList = [...tempPlanList, planList];
                                }
                            })
                    }

                }
                else {
                    currentWebsitePlanList[`normal`].map((planList) => {
                        if ((activePlanInterval === "M" || activePlanInterval === null) && planList.monthly_price >= activePlanPrice) {
                            tempPlanList = [...tempPlanList, planList]
                        } else if (activePlanInterval === "Y" && planList.monthly_price >= activePlanPrice) {
                            tempPlanList = [...tempPlanList, planList]
                        }
                    })
                }
            }
            // check if 2- multi domain plan
            else if (activePlanType === 2) {
                if (planPackage) {
                    if (planPackage.platforms === "dynamic") {
                        currentWebsitePlanList[`multi`]
                            .filter(planList => planList.is_dynamic !== 1) // Exclude is_dynamic=1
                            .forEach(planList => {
                                if (planPackage.package_id === planList.id) {
                                    tempPlanList = [...tempPlanList, planList];
                                }
                            });
                    } else {
                        currentWebsitePlanList[`multi`]
                            .filter(planList => planList.is_dynamic !== 1) // Exclude is_dynamic=1
                            .forEach(planList => {
                                if (activePlanInterval === "M" && planList.monthly_price >= activePlanPrice) {
                                    tempPlanList = [...tempPlanList, planList];
                                } else if (activePlanInterval === "Y" && planList.price >= activePlanPrice) {
                                    tempPlanList = [...tempPlanList, planList];
                                }
                            });
                    }
                }
            }
            // check if 3 - Pre purchase plan
            else if (activePlanType === 3) {
                if (planPackage) {
                    if (planPackage.platforms === "dynamic") {
                        currentWebsitePlanList[`dynamic`]
                            .filter(planList => planList.is_dynamic !== 1) // Exclude is_dynamic=1
                            .forEach(planList => {
                                if ((activePlanInterval === "M" || activePlanInterval === null) && planList.monthly_price >= activePlanPrice && planList.id === planPackage.package_id) {
                                    tempPlanList = [...tempPlanList, planList];
                                } else if (activePlanInterval === "Y" && planList.price >= activePlanPrice && planList.id === planPackage.package_id) {
                                    tempPlanList = [...tempPlanList, planList];
                                }
                            });
                    } else {

                        // Fall back to multi plans if no eligible dynamic plans are found    
                        if (!tempPlanList.length) {
                            currentWebsitePlanList[`multi`]
                                .filter(planList => planList.is_dynamic !== 1) // Exclude is_dynamic=1
                                .forEach(planList => {
                                    if (activePlanInterval === "M" && planList.monthly_price >= activePlanPrice) {
                                        tempPlanList = [...tempPlanList, planList];
                                    } else if (activePlanInterval === "Y" && planList.price >= activePlanPrice) {
                                        tempPlanList = [...tempPlanList, planList];
                                    }
                                });
                        }
                    }
                }
                else {
                    currentWebsitePlanList[`multi`]
                        .filter(planList => planList.is_dynamic !== 1) // Exclude is_dynamic=1
                        .forEach(planList => {
                            if (activePlanInterval === "M" && planList.monthly_price >= activePlanPrice) {
                                tempPlanList = [...tempPlanList, planList];
                            } else if (activePlanInterval === "Y" && planList.price >= activePlanPrice) {
                                tempPlanList = [...tempPlanList, planList];
                            }
                        });
                }
            }

            setUpgradePlanList(tempPlanList);

        }


    }, [activePlanType, activePlanInterval, activePlanPrice]);



    // Retrieve the ID of the free widget plan
    useEffect(() => {
        if (currentWebsitePlanList && currentWebsitePlanList["free-widget"]) {
            setFreePlanID(currentWebsitePlanList["free-widget"][0].id);
        }

    }, [currentWebsitePlanList])

    // Handle widget plan purchase
    const handlePurchaseWidgetPlan = (planID: number) => {
        if (user && activeLanguage && planID) {
            const urlString = `${planID}|${activePlanDomain}|${planInterval}|${user.id}|${activeLanguage.code}|1`;
            const encodedString = encodeBase64(urlString);
            navigate(`/front/package/${encodedString}`);
        }
    }

    // Widget multi-store Plan Purchase Event
    const handleMultiStoreWidgetPlan = (planID: number) => {
        console.log(`test: upgrade here 1`)
        if (user && activeLanguage && planID) {
            const urlString = `${planID}|${`multi-store`}|${planInterval}|${user.id}|${activeLanguage.code}|${5}`;
            const encodedString = encodeBase64(urlString);
            navigate(`/front/package/${encodedString}`);
        }
    }

    // Handle multi-domain widget plan purchase
    const handleMultiDomainWidgetPlan = (planID: number) => {


        if (user && activeLanguage && planID) {
            const urlString = `${planID}|${`multi`}|${planInterval}|${user.id}|${activeLanguage.code}|${3}`;
            const encodedString = encodeBase64(urlString);
            navigate(`/front/package/${encodedString}`);
        }
    }

    // Handle purchase of a normal domain plan for adding a new domain
    const handlePurchaseNormalDomainPlan = (planID: number) => {
        if (accessToken && user && planID && activeLanguage) {
            saveNormalDomainService(accessToken, activePlanDomain, user.id, planID, planInterval, activeLanguage.code, planID === freePlanID, dispatch, navigate, t)
        }
    }

    const handlePurchase = (planID: number) => {
        switch (activePlanType) {
            case 1:
                handlePurchaseWidgetPlan(planID);
                break;
            case 2:
                handleMultiStoreWidgetPlan(planID);
                break;
            case 3:
                if (activePlanPrice === 0) {
                    handleMultiDomainWidgetPlan(planID);
                }
                else {
                    handleMultiStoreWidgetPlan(planID);
                }
                break
            default:
        }
    }

    return (
        <>
            <div className="aioa_dashboard-widget-plan-list">
                {(planPackage
                    && (planPackage.platforms === "dynamic")
                    && (upgradePlanList.length)
                    && (upgradePlanList[0].id === planPackage.package_id))
                    ? <>
                    </>
                    : <>
                        {(activePlanInterval === "M" || activePlanInterval === null) && planPackage && (planPackage.slug !== "extra-large-site") && (
                            <>
                                <div className="aioa_dashboard-plan-list-toggle">
                                    <span className={`toggle-label monthly ${planInterval === 'M' ? `active` : ``}`} role="button" onClick={() => setPlanInterval('M')}>{t('Pay Monthly')}</span>
                                    <Form.Check // prettier-ignore
                                        type="switch"
                                        id="custom-switch"
                                        aria-label="Toggle Annual Plan"
                                        checked={planInterval === 'Y'}
                                        onChange={() => setPlanInterval(planInterval === 'M' ? 'Y' : 'M')}
                                    />
                                    <span className={`toggle-label annually ${planInterval === 'Y' ? `active` : ``}`} role="button" onClick={() => setPlanInterval('Y')}>
                                        {t('Pay Annually')}
                                        <span className="discount">
                                            Save 20%
                                            {/* {t("Save",{percentage: `20%`})} */}
                                        </span>
                                    </span>
                                    {/* <ul>
                                        <li><Button className={`${planInterval === 'M' ? `active` : ``}`} variant={`${planInterval === 'M' ? `primary` : `secondary`}`} onClick={() => setPlanInterval('M')}>{t('Pay Monthly')}</Button></li>
                                        <li><Button className={`${planInterval === 'Y' ? `active` : ``}`} variant={`${planInterval === 'Y' ? `primary` : `secondary`}`} onClick={() => setPlanInterval('Y')}>{t('Pay Yearly')}</Button></li>
                                    </ul> */}
                                </div>
                            </>
                        )}
                    </>
                }


                <Row className="gx-2 gy-4" xs={1} md={2} xl={(forAddNewDomain && user && user.department !== 4) ? upgradePlanList.length + 1 : upgradePlanList.length}>
                    {(upgradePlanList && upgradePlanList.length > 0) ? (
                        <>
                            {(forAddNewDomain && user && user.department !== 4) ?
                                <Col>
                                    <Card className="aioa_dashboard-widget-plan-box">
                                        <Card.Header className="h5">
                                            <div className="aioa_dashboard-widget-plan-box-name">
                                                <span>
                                                    {t("Free Widget")}</span>
                                            </div>
                                            <div className="aioa_dashboard-widget-plan-box-price">
                                                <div className="aioa_dashboard-widget-plan-box-offer-price">$0<span>/{planInterval === "M" ? t('Per Month') : t('Per Year')}</span>
                                                </div>
                                            </div>
                                        </Card.Header>
                                        <Card.Body>
                                            <div className="aioa_dashboard-widget-plan-box-description">{t('Plan Description Text', { pageViews: 10 })}</div>

                                            <div className="aioa_dashboard-widget-plan-box-btn">
                                                {freePlanID ? <Button variant="primary" size="lg" onClick={() => handlePurchaseNormalDomainPlan(freePlanID)}>{t('Select Plan')}</Button> : ""}
                                            </div>
                                        </Card.Body>
                                    </Card>
                                </Col>
                                : <></>}



                            {upgradePlanList.map((planItem) => {
                                return (
                                    <Col key={planItem.id}>
                                        <Card className={`aioa_dashboard-widget-plan-box 
                                        ${(currentWebsitePlanStatus.isProPlan && planInterval === activePlanInterval && planItem.monthly_price === activePlanPrice) ? 'active' : ''} 
                                        ${(planInterval === activePlanInterval && planItem.monthly_price === activePlanPrice) ? 'current' : ''}`}>
                                            <Card.Header className="h5">
                                                <div className="aioa_dashboard-widget-plan-box-name">
                                                    <span>
                                                        {planItem.name}
                                                    </span>
                                                    {currentWebsitePlanStatus.isProPlan && <div className="status active">{t("Active")}</div>}
                                                    {currentWebsitePlanStatus.isCanceledPlan && <div className="status cancelled">{t("Cancelled")}</div>}
                                                    {currentWebsitePlanStatus.isExpired && <div className="status expired">{t("Expired")}</div>}

                                                </div>
                                                <div className="aioa_dashboard-widget-plan-box-price">
                                                    {user && user.department === 4 && currentUserDiscount && currentUserDiscount.discount_value > 0 ? (
                                                        <>
                                                            {planInterval === "M" ? (
                                                                <>
                                                                    {/* <div className="aioa_dashboard-widget-plan-box-strike-price"><del>${planItem.monthly_price.toFixed(2)}<span>/{t('Per Month')}</span></del></div> */}
                                                                    <div className="aioa_dashboard-widget-plan-box-offer-price">
                                                                        $<StrikePrice price={planItem.monthly_price} />
                                                                        <span>/{t('Per Month')}</span></div>

                                                                </>
                                                            ) : (
                                                                <>
                                                                    <div className="aioa_dashboard-widget-plan-box-strike-price">
                                                                        {/* <del>
                                                                            ${planItem.price.toFixed(2)}
                                                                            <span>/{t('Per Year')}</span>
                                                                        </del> */}
                                                                    </div>
                                                                    <div className="aioa_dashboard-widget-plan-box-offer-price">$<StrikePrice price={planItem.price} /><span>/{t('Per Year')}</span></div>
                                                                </>
                                                            )}
                                                        </>
                                                    ) : (
                                                        <>
                                                            {planInterval === "M" ? (
                                                                <>
                                                                    <div className="aioa_dashboard-widget-plan-box-offer-price">${planItem.monthly_price}<span>/{t('Per Month')}</span></div>
                                                                </>
                                                            ) : (
                                                                <>
                                                                    <div className="aioa_dashboard-widget-plan-box-offer-price">${planItem.price}<span>/{t('Per Year')}</span></div>
                                                                </>
                                                            )}
                                                        </>
                                                    )}
                                                </div>
                                                {(currentWebsitePlanStatus.isProPlan && planPackage && planPackage.subscr_interval === planInterval && planItem.monthly_price === activePlanPrice)
                                                    ? <>

                                                        <div className="aioa_dashboard-widget-plan-box-renewal-badge">
                                                            {/* {t("Next Renewal on", { date: parseISODate(planPackage.end_date) })} */}
                                                            Next Renewal on {parseISODate(planPackage.end_date)}
                                                        </div>
                                                        <div className="aioa_dashboard-widget-plan-box-divider"></div>
                                                    </>
                                                    : <></>
                                                }

                                            </Card.Header>
                                            <Card.Body>
                                                <div className="aioa_dashboard-widget-plan-box-description">
                                                    {t('Plan Description Text', { pageViews: planItem.page_views })}
                                                </div>


                                                {activePlanType === 2 && (
                                                    <>
                                                        <div className="aioa_dashboard-widget-plan-domain-description">
                                                            {t('plan domain info', { domainCount: planItem.slug === 'silver' ? 3 : planItem.slug === 'gold' ? 5 : planItem.domain_limit ? planItem.domain_limit : 10 })}
                                                        </div>
                                                    </>
                                                )}

                                                {activePlanType === 3 && (
                                                    <>
                                                        <div className="aioa_dashboard-widget-plan-domain-description">
                                                            {t('plan domain info', { domainCount: planItem.domain_limit || 0 })}
                                                        </div>
                                                    </>
                                                )}


                                                <div className="aioa_dashboard-widget-plan-box-btn">
                                                    {forAddNewDomain ? (
                                                        <>
                                                            {activePlanType === 1 ? (
                                                                <>
                                                                    <Button variant="primary" size="lg" onClick={() => handlePurchaseNormalDomainPlan(planItem.id)} disabled={disabledButton}>{t('Select Plan')}</Button>
                                                                </>
                                                            ) : (
                                                                <>

                                                                </>
                                                            )}
                                                        </>
                                                    ) : (
                                                        <>
                                                            {(currentWebsite && planPackage && planPackage.subscr_interval === planInterval && planItem.monthly_price === activePlanPrice && planPackage) ?
                                                                <>
                                                                    {currentWebsitePlanStatus.isCanceledPlan &&
                                                                        <span className="btn btn-lg btn-danger">
                                                                            Expires on {parseISODate(planPackage.end_date)}
                                                                            {/* {t("Expires on", { date: parseISODate(planPackage.end_date) })} */}
                                                                        </span>

                                                                    }
                                                                    {currentWebsitePlanStatus.isExpired &&
                                                                        <Button
                                                                            variant="primary"
                                                                            size="lg"
                                                                            className="w-100"
                                                                            onClick={() => {
                                                                                handlePurchase(planItem.id)
                                                                            }}
                                                                            disabled={disabledButton}>
                                                                            {t('Renew Now')}
                                                                        </Button>
                                                                    }
                                                                    {currentWebsitePlanStatus.isProPlan &&
                                                                        <PaymentButtonBlock
                                                                            buttonType={3}
                                                                            activePlanType={activePlanType}
                                                                            activePlanDomain={currentWebsite.domain}
                                                                            activePlanInterval={planPackage.subscr_interval}
                                                                            activePlanPrice={planPackage.final_price}
                                                                            activePlanOfferPrice={currentWebsite.cancel_discount_price}
                                                                            activePlanOfferPr={currentWebsite.cancel_discount_percentage}
                                                                            websiteID={currentWebsite.id}
                                                                            activeUserID={currentWebsite.user_id}
                                                                            activePlanID={planPackage.package_id}
                                                                            buttonBigSize={true}
                                                                            buttonVariant={`danger`}
                                                                            buttonLabel={`Cancel Plan`}
                                                                            isShowDiscount={false}
                                                                        />

                                                                    }
                                                                </>
                                                                : <Button variant="primary" size="lg" className="w-100" onClick={() => handlePurchase(planItem.id)} disabled={disabledButton}>
                                                                    {(activePlanType === 3 && user && user.is_multiple_domain && !user.is_multi_domain_purchased)
                                                                        ? <>{t('Purchase Now')}</>
                                                                        : <>{currentWebsitePlanStatus.isExpired || currentWebsitePlanStatus.isCanceledPlan ? t('Purchase Now') : t('Upgrade Plan')}</>
                                                                    }

                                                                </Button>
                                                            }
                                                        </>
                                                    )}
                                                </div>
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                )
                            })}
                        </>
                    ) : <>
                        {t('Contact us to upgrade or purchase a plan.')}
                    </>}
                </Row>
            </div>
        </>
    )
}

export default UpgradePlanList;
