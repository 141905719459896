import { Card } from "react-bootstrap"
import { useSelector } from "react-redux";
import { StoreProptypes } from "../../PropTypes/StoreProptypes";
import parseISODate from "../../Helpers/parseISODate";
import { useTranslation } from "react-i18next";
import PaymentButtonBlock from "../PaymentButton";
import StrikePrice from "../../Helpers/strikePrice";
import UpgradePlanList from "../PaymentButton/UpgradePlanList";

const NormalUserCurrentExpiredPlanInfo = () => { // only Normal user Plan Details

    // Functions
    const { t } = useTranslation();

    // Redux Data
    const { currentWebsite, user } = useSelector((store: StoreProptypes) => store);
    console.log("hello from ExpiredPlanPlanInfo");

    return (
        <>
            {currentWebsite && currentWebsite.expired_package_detail && currentWebsite.expired_package_detail.length > 0 && (
                <>
                    {/* <Card className="aioa_dashboard-plan-info-box normal-user expired-plan-box ">
                        <Card.Body>
                            <div className="aioa_dashboard-plan-info-box-label">{t('Expired Plan Label')}</div>
                            <div className="aioa_dashboard-plan-info-box-left">
                                <h4 className="text-primary mb-1">{currentWebsite.expired_package_detail[0].name} Plan</h4>
                                {currentWebsite.is_free_widget !== 1 && (
                                    <>
                                        <p className="text-danger mb-0"><strong>{t('Plan Expired Information', { expiredDate: parseISODate(currentWebsite.expired_package_detail[0].end_date) })} </strong></p>
                                    </>
                                )}
                            </div>
                            <div className="aioa_dashboard-plan-info-box-right">
                                <div className="aioa_dashboard-plan-info-box-price">
                                    {currentWebsite.expired_package_detail[0].subscr_interval === 'Y' ? (
                                        <>
                                            <strong className="original-price">${(currentWebsite.expired_package_detail[0].final_price).toFixed(2)}<span>/{t('Per Year')}</span></strong>

                                        </>
                                    ) : (
                                        <>
                                            <strong className="original-price">${(currentWebsite.expired_package_detail[0].final_price).toFixed(2)}<span>/{t('Per Month')}</span></strong>
                                        </>
                                    )}
                                </div>
                                <div className="aioa_dashboard-plan-info-box-btn">
                                    <PaymentButtonBlock
                                        buttonType={2}
                                        activePlanType={1}
                                        activePlanDomain={currentWebsite.domain}
                                        activePlanInterval={currentWebsite.expired_package_detail[0].subscr_interval}
                                        activePlanPrice={currentWebsite.expired_package_detail[0].final_price}
                                        websiteID={currentWebsite.id}
                                        activeUserID={currentWebsite.user_id}
                                        activePlanID={currentWebsite.expired_package_detail[0].package_id}
                                        buttonBigSize={true}
                                        buttonVariant={`danger`}
                                        buttonLabel={`Renew Now Button`}
                                    />
                                </div>
                            </div>
                        </Card.Body>
                    </Card> */}
                    <div className="aioa_dashboard-upgrade-plan-info-box">
                        <UpgradePlanList
                            activePlanType={1}
                            activePlanDomain={currentWebsite.domain}
                            activePlanInterval={currentWebsite.expired_package_detail[0].subscr_interval}
                            activePlanPrice={currentWebsite.expired_package_detail[0].package_monthly_price}
                        />
                    </div>
                </>
            )}
        </>
    )
}

const PrePurchasedPartnerUserCurrentExpiredPlanInfo = () => { // only PrePurchased Partner user Plan Details

    // Functions
    const { t } = useTranslation();

    // Redux Data
    const { currentWebsite } = useSelector((store: StoreProptypes) => store);

    return (
        <>
            {currentWebsite && currentWebsite.expired_package_detail && currentWebsite.expired_package_detail.length > 0 && (
                <>
                    <Card className="aioa_dashboard-plan-info-box prepurchased-Partner expired-plan-box">
                        <Card.Body>
                            <div className="aioa_dashboard-plan-info-box-label">{t('Expired Plan Label')}</div>
                            <div className="aioa_dashboard-plan-info-box-left">
                                <h4 className="text-primary mb-1">{currentWebsite.expired_package_detail[0].name} Plan</h4>
                                {currentWebsite.is_free_widget !== 1 && (
                                    <>
                                        <p className="text-danger mb-0"><strong>{t('Plan Expired Information', { expiredDate: parseISODate(currentWebsite?.expired_package_detail[0].end_date) })} </strong></p>
                                    </>
                                )}
                            </div>
                            <div className="aioa_dashboard-plan-info-box-right">
                                <div className="aioa_dashboard-plan-info-box-price">
                                    {currentWebsite.expired_package_detail[0].subscr_interval === 'Y' ? (
                                        <>
                                            <strong className="original-price">
                                                <del>${(currentWebsite.expired_package_detail[0].price).toFixed(2)}<span>/{t('Per Year')}</span></del>
                                            </strong>
                                            <strong className="original-price">
                                                $<StrikePrice price={parseFloat((currentWebsite.expired_package_detail[0].final_price).toFixed(2))} isDiscountPr={false} discountPr={currentWebsite.reactivate_discount} />
                                                <span>/{t('Per Year')}</span>
                                            </strong>
                                        </>
                                    ) : (

                                        <>
                                            <strong className="original-price">
                                                <del>${(currentWebsite.expired_package_detail[0].package_monthly_price).toFixed(2)}<span>/{t('Per Month')}</span></del>
                                            </strong>
                                            <strong className="original-price">
                                                $<StrikePrice price={parseFloat((currentWebsite.expired_package_detail[0].package_monthly_price).toFixed(2))} isDiscountPr={false} discountPr={currentWebsite.reactivate_discount} />
                                                <span>/{t('Per Month')}</span>
                                            </strong>
                                        </>
                                    )}
                                </div>
                                <div className="aioa_dashboard-plan-info-box-btn">
                                    <PaymentButtonBlock
                                        buttonType={4}
                                        activePlanType={2}
                                        activePlanDomain={currentWebsite.domain}
                                        activePlanInterval={currentWebsite.expired_package_detail[0].subscr_interval}
                                        activePlanPrice={currentWebsite.expired_package_detail[0].final_price}
                                        websiteID={currentWebsite.id}
                                        activeUserID={currentWebsite.user_id}
                                        activePlanID={currentWebsite.expired_package_detail[0].package_id}
                                        buttonBigSize={true}
                                        buttonVariant={`danger`}
                                        buttonLabel={`Renew Now Button`}
                                    />


                                </div>
                            </div>
                        </Card.Body>
                    </Card>
                </>
            )}
        </>
    )
}

const NormalPartnerUserCurrentExpiredPlanInfo = () => { // only Normal Partner user Plan Details

    // Functions
    const { t } = useTranslation();

    // Redux Data
    const { currentWebsite } = useSelector((store: StoreProptypes) => store);

    return (
        <>
            {currentWebsite && currentWebsite.expired_package_detail && currentWebsite.expired_package_detail.length > 0 && (
                <>
                    <Card className="aioa_dashboard-plan-info-box normal-partner expired-plan-box">
                        <Card.Body>
                            <div className="aioa_dashboard-plan-info-box-label">{t('Expired Plan Label')}</div>
                            <div className="aioa_dashboard-plan-info-box-left">
                                <h4 className="text-primary mb-1">{currentWebsite.expired_package_detail[0].name} Plan</h4>
                                {currentWebsite.is_free_widget !== 1 && (
                                    <>
                                        <p className="text-danger mb-0"><strong>{t('Plan Expired Information', { expiredDate: parseISODate(currentWebsite?.expired_package_detail[0].end_date) })} </strong></p>
                                    </>
                                )}
                            </div>
                            <div className="aioa_dashboard-plan-info-box-right">
                                <div className="aioa_dashboard-plan-info-box-price">
                                    {currentWebsite.expired_package_detail[0].subscr_interval === 'Y' ? (
                                        <>
                                            <strong className="original-price">${(currentWebsite.expired_package_detail[0].final_price).toFixed(2)}/{t('Per Year')}</strong>
                                        </>
                                    ) : (
                                        <>
                                            <strong className="original-price">${(currentWebsite.expired_package_detail[0].final_price).toFixed(2)}/{t('Per Month')}</strong>
                                        </>
                                    )}
                                </div>
                                <div className="aioa_dashboard-plan-info-box-btn">
                                    <PaymentButtonBlock
                                        buttonType={2}
                                        activePlanType={1}
                                        activePlanDomain={currentWebsite.domain}
                                        activePlanInterval={currentWebsite.expired_package_detail[0].subscr_interval}
                                        activePlanPrice={currentWebsite.expired_package_detail[0].final_price}
                                        websiteID={currentWebsite.id}
                                        activeUserID={currentWebsite.user_id}
                                        activePlanID={currentWebsite.expired_package_detail[0].package_id}
                                        buttonBigSize={true}
                                        buttonVariant={`danger`}
                                        buttonLabel={`Renew Now Button`}
                                    />


                                </div>
                            </div>
                        </Card.Body>
                    </Card>
                </>
            )}
        </>
    )
}

const MultiStoreUserCurrentExpiredPlanInfo = () => { // only Multi Store user Plan Details

    // Functions
    const { t } = useTranslation();

    // Redux Data
    const { currentWebsite } = useSelector((store: StoreProptypes) => store);

    return (
        <>
            {currentWebsite && currentWebsite.expired_package_detail && currentWebsite.expired_package_detail.length > 0 && (
                <>
                    <Card className="aioa_dashboard-plan-info-box normal-user expired-plan-box">
                        <Card.Body>
                            <div className="aioa_dashboard-plan-info-box-label">{t('Expired Plan Label')}</div>
                            <div className="aioa_dashboard-plan-info-box-left">
                                <h4 className="text-primary mb-1">{currentWebsite.expired_package_detail[0].name} Plan</h4>
                                {currentWebsite.is_free_widget !== 1 && (
                                    <>
                                        <p className="text-danger mb-0"><strong>{t('Plan Expired Information', { expiredDate: parseISODate(currentWebsite?.expired_package_detail[0].end_date) })} </strong></p>
                                    </>
                                )}
                            </div>
                            <div className="aioa_dashboard-plan-info-box-right">
                                <div className="aioa_dashboard-plan-info-box-price">
                                    {currentWebsite.expired_package_detail[0].subscr_interval === 'Y' ? (
                                        <>
                                            <strong className="original-price">${(currentWebsite.expired_package_detail[0].final_price).toFixed(2)}<span>/{t('Per Year')}</span></strong>
                                        </>
                                    ) : (
                                        <>
                                            <strong className="original-price">${(currentWebsite.expired_package_detail[0].final_price).toFixed(2)}<span>/{t('Per Month')}</span></strong>
                                        </>
                                    )}
                                </div>
                                <div className="aioa_dashboard-plan-info-box-btn">
                                    <PaymentButtonBlock
                                        buttonType={4}
                                        activePlanType={2}
                                        activePlanDomain={currentWebsite.domain}
                                        activePlanInterval={currentWebsite.expired_package_detail[0].subscr_interval}
                                        activePlanPrice={currentWebsite.expired_package_detail[0].final_price}
                                        websiteID={currentWebsite.id}
                                        activeUserID={currentWebsite.user_id}
                                        activePlanID={currentWebsite.expired_package_detail[0].package_id}
                                        buttonBigSize={true}
                                        buttonVariant={`danger`}
                                        buttonLabel={`Renew Now Button`}
                                    />


                                </div>
                            </div>
                        </Card.Body>
                    </Card>
                </>
            )}
        </>
    )
}

const AllUserOnlyExpiredPlanInfo = () => {

    // Redux Data
    const { currentWebsite, user } = useSelector((store: StoreProptypes) => store);

    return (
        <>
            {currentWebsite && (
                <>
                    {user && (
                        <>
                            {user.department === 4 ? ( // For Partner User Only
                                <>
                                    {(user.is_multiple_domain === 1) ? (
                                        <>
                                            <PrePurchasedPartnerUserCurrentExpiredPlanInfo />
                                        </>
                                    ) : ( // Normal Partner plan with upgrade one step up
                                        <>
                                            <NormalPartnerUserCurrentExpiredPlanInfo />
                                        </>
                                    )}
                                </>
                            ) : (
                                <>
                                    {user.is_multiple_domain === 2 ? ( // Sliver Gold Plan with Add New plan
                                        <>
                                            <MultiStoreUserCurrentExpiredPlanInfo />
                                        </>
                                    ) : ( // Normal Plan with one stepup upgrade option
                                        <>
                                            <NormalUserCurrentExpiredPlanInfo />
                                        </>
                                    )}
                                </>
                            )}
                        </>
                    )}
                </>
            )}

        </>
    )
}

export default AllUserOnlyExpiredPlanInfo;
